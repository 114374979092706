import React from 'react';
import { AwsRum } from 'aws-rum-web';
import { Box, Button, Icon, Typography } from '@mui/material';

let awsRum = null
try {
    const config = {
        sessionSampleRate: 1,
        guestRoleArn: `${process.env.REACT_APP_GUEST_ROLE_URL}`,
        identityPoolId: `${process.env.REACT_APP_IDENTITY_POOLID_URL}`,
        endpoint: `${process.env.REACT_APP_LOGGING_ENDPOINT_URL}`,
        telemetries: ["performance", "errors", "http"],
        allowCookies: true,
        enableXRay: false
    };

    const APPLICATION_ID = `${process.env.REACT_APP_LOGGING_APPLICATION_ID_URL}`;
    const APPLICATION_VERSION = '1.0.0';
    const APPLICATION_REGION = 'us-east-2';

    awsRum = new AwsRum(
        APPLICATION_ID,
        APPLICATION_VERSION,
        APPLICATION_REGION,
        config
    );
} catch (error) {
    // Ignore errors thrown during CloudWatch RUM web client initialization
}

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }
    componentDidCatch(error, errorInfo) {
        //console.log('recordingError: ' + error)
        awsRum.recordError(error);
    }
    render() {
        if (this.state.hasError) {
            return (
                <Box display={'flex'} justifyContent={'center'} flexDirection={'column'} position={'absolute'} top={'5%'} left={'35%'}>
                    <Icon style={{ height: '100%', width: '110%' }} name={"page-not-found"} size={557} fill={"blue"} />
                    <Typography textAlign={'center'} fontWeight={'bold'} fontSize={'30px'}>Something went wrong.</Typography>
                    <Button onClick={() => window.location.reload()} sx={{ marginTop: '20px' }}>Click here to refresh the page</Button>
                </Box>
            );
        }
        return this.props.children;
    }
}
export default ErrorBoundary;
