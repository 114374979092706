import { toast } from "react-toastify";

const TYPE = {
    DEFAULT: "default",
    INFO: "info",
    WARNING: "warning",
    SUCCESS: "success",
    ERROR: "error",
}

const clearPreviousToasts = () => {
    toast.dismiss();
    toast.clearWaitingQueue();
}

const showToast = (message, type = TYPE.DEFAULT) => {
    clearPreviousToasts();
    toast(message, { type })
}

const ToastUtils = { TYPE, showToast, clearPreviousToasts };
export default ToastUtils;