export const UserRole = {
  admin: "Admin",
  typist: "Typist",
  proofReader: "ProofReader",
  supervisor: "Supervisor",
  customer: "Client",
};
export const JobStatus = {
  IN_QUEUE: "IN_QUEUE",
  TYPING: "TYPING",
  ON_HOLD: "ON_HOLD",
  IN_REVIEW: "IN_REVIEW",
  REVIEW: "REVIEW",
  DELIVERED: "DELIVERED",
  RE_OPEN: "RE_OPEN",
  ALSO_IN_QUEUE: "IN QUEUE"
};
export const JobStatusTitle = {
  IN_QUEUE: "IN QUEUE",
  TYPING: "TYPING",
  ON_HOLD: "ON HOLD",
  IN_REVIEW: "IN REVIEW",
  REVIEW: "REVIEW",
  DELIVERED: "DELIVERED",
  REOPEN: "RE_OPEN",
  ALSO_REOPEN:"RE OPEN",
  ALSO_IN_QUEUE: "IN_QUEUE",
  TRANSCRIBING: "TRANSCRIPTION_IN_PROGRESS",
  TX_COMPLETED: "TRANSCRIPTION_COMPLETED",
  COMPLETED: "COMPLETED",
  ALSO_ON_HOLD: "ON_HOLD",
  ALSO_IN_REVIEW: "IN_REVIEW"
};

export const BookMark = "TranscribeText";
export const PlaybackSpeeds = [0.25, 0.5, 0.75, 1, 1.25, 1.5, 2.0];

export const RoleUrl = `http://schemas.microsoft.com/ws/2008/06/identity/claims/role`;
