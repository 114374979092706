const increaseAPICount = () => {
    let apiCount = parseInt(localStorage.getItem("apiCount")) || 0;
    localStorage.setItem("apiCount", apiCount + 1);
    updateLoaderVisibility();
}

const decreaseAPICount = () => {
    let apiCount = parseInt(localStorage.getItem("apiCount")) || 0;
    if (apiCount > 0) {
        localStorage.setItem("apiCount", apiCount - 1);
    }
    updateLoaderVisibility();
}

const resetAPICount = () => {
    localStorage.setItem("apiCount", 0);
    updateLoaderVisibility();
}

const getLoaderCount = () => {
    return parseInt(localStorage.getItem("apiCount")) || 0;
}

const updateLoaderVisibility = () => {
    const apiCount = getLoaderCount();
    if (apiCount === 0) {
        hideLoader();
    } else {
        showLoader();
    }
}

const showLoader = () => {
    document.getElementById('loading-container').classList.add("show");
}

const hideLoader = () => {
    document.getElementById('loading-container').classList.remove("show");
}

const loaderManager = {
    increaseAPICount,
    decreaseAPICount,
    resetAPICount,
    showLoader,
    hideLoader,
    getLoaderCount,
    updateLoaderVisibility
};

export default loaderManager;