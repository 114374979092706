import React, { Suspense, lazy } from "react";
import {
    Navigate,
    Route,
    Routes
} from "react-router-dom";
import { UserRole } from "../Constants/MetaData";
const TypistDashboard = lazy(() => import("../Pages/TypistPage/TypistDashboard"));
const CustomerDashboard = lazy(() => import("../Pages/Customer/CustomerDashboard"));
const Users = lazy(() => import("../Pages/Supervisor/Users"));
const AddClient = lazy(() => import("../Pages/Supervisor/addClients"));
const EditClient = lazy(() => import('../Pages/Supervisor/editClient'));
const AddUsers = lazy(() => import("../Pages/Supervisor/addUsers"));
const EditUsers = lazy(() => import("../Pages/Supervisor/editUsers"));
const BillingInfoAndReport = lazy(() => import("../Pages/CommonPages/BillingInfoAndReport"));
const AllTemplates = lazy(() => import("../Pages/Customer/Templates"));
const Invite = lazy(() => import("../Pages/Customer/Invite"));
const NoRoleUserMessage = lazy(() => import("../Pages/NoRoleUserPage/index"));
const Clients = lazy(() => import("../Pages/Supervisor/Clients"));
const UpdateMyProfile = lazy(() => import("../Pages/UpdateMyProfile/updateMyProfile"));
const PageNotFound = lazy(() => import("../Pages/CommonPages/PageNotFound"));
const ServerDown = lazy(() => import("../Pages/CommonPages/ServerDownPage"));
const JobDashboard = lazy(() => import("../Pages/CommonPages/JobDashboard"));
const UploadAdvanceEditDocument = lazy(() => import("../Pages/CommonPages/uploadDocument"));
const IdleTimeWrapper = lazy(() => import("../Pages/CommonPages/IdleTimeWrapper"));

const AppRoutes = ({ roleType }) => {
    const getRoleBasedRoutes = () => {
        switch (roleType) {
            case UserRole.admin:
                return <Routes>
                    <Route path="/">
                        <Route index element={<JobDashboard roleType={roleType} />} />
                        <Route exact path="users">
                            <Route exact index element={<Users roleType={roleType} />} />
                            <Route exact path="new-user" element={<AddUsers />} />
                        </Route>
                        <Route exact path=":role">
                            <Route exact path=":userId" element={<EditUsers />} />
                        </Route>
                        <Route path="clients">
                            <Route index element={<Clients roleType={roleType} />} />
                            <Route exact path="new-client" element={<AddClient />} />
                            <Route exact path=":clientId" element={<EditClient />} />
                        </Route>
                        <Route path="reports" >
                            <Route exact index element={<Navigate to="/reports/typist" />} />
                            <Route path=":type" element={<BillingInfoAndReport />} />
                        </Route>
                        <Route exact path="invite" element={<Invite />} />
                        <Route exact path="server-down" element={<ServerDown />} />
                        <Route exact path="*" element={<PageNotFound />} />
                    </Route>
                    <Route exact path="*" element={<PageNotFound />} />
                </Routes>

            case UserRole.typist:
                return <Routes>
                    <Route exact path="/" element={<TypistDashboard />} />
                    <Route exact path="my-profile" element={<UpdateMyProfile roleType={roleType} />} />
                    <Route path="typist">
                        <Route index element={<Navigate to="/" />} />
                        <Route path=":jobId">
                            <Route exact index element={<IdleTimeWrapper roleType={roleType} />} />
                            <Route path="split">
                                <Route index element={<Navigate to=".." />} /> {/* Going back to parent job */}
                                <Route exact path=":splitId" element={<IdleTimeWrapper roleType={roleType} />} />
                            </Route>
                            <Route exact path="advance-editing" element={<UploadAdvanceEditDocument />} />
                        </Route>
                    </Route>
                    <Route exact path="*" element={<PageNotFound />} />
                </Routes>

            case UserRole.proofReader:
                return <Routes>
                    <Route exact path="/" element={<JobDashboard roleType={roleType} />} />
                    <Route exact path="users" element={<Users roleType={roleType} />} />
                    <Route exact path="clients" element={<Clients roleType={roleType} />} />
                    <Route exact path="my-profile" element={<UpdateMyProfile roleType={roleType} />} />

                    <Route exact path="jobs" >
                        {/* <Route exact path=":jobId" element={<UploadAdvanceEditDocument />} /> */}
                        <Route path=":jobId">
                            <Route exact index element={<IdleTimeWrapper roleType={roleType} />} />
                            <Route path="split">
                                <Route index element={<Navigate to=".." />} /> {/* Going back to parent job */}
                                <Route exact path=":splitId" element={<IdleTimeWrapper roleType={roleType} />} />
                            </Route>
                            {/* <Route exact path="advance-editing" element={<UploadAdvanceEditDocument />} /> */}
                        </Route>
                    </Route>
                    <Route exact path="*" element={<PageNotFound />} />
                </Routes>

            case UserRole.supervisor:
                return <Routes>
                    <Route exact path="/" element={<JobDashboard roleType={roleType} />} />
                    <Route exact path="users">
                        <Route exact index element={<Users roleType={roleType} />} />
                        <Route exact path="new-user" element={<AddUsers />} />
                        <Route exact path=":userId" element={<EditUsers />} />
                    </Route>
                    <Route path="clients">
                        <Route index element={<Clients roleType={roleType} />} />
                        <Route exact path="new-client" element={<AddClient />} />
                        <Route exact path=":clientId" element={<EditClient />} />
                    </Route>
                    <Route exact path="*" element={<PageNotFound />} />
                </Routes>

            case UserRole.customer:
                return <Routes>
                    <Route path="/">
                        <Route index element={<CustomerDashboard roleType={roleType}/>} />
                        <Route exact path="templates" element={<AllTemplates />} />
                        <Route exact path="invite" element={<Invite />} />
                        <Route exact path="my-profile" element={<UpdateMyProfile roleType={roleType} />} />
                        <Route exact path="*" element={<PageNotFound />} />
                    </Route>
                    <Route exact path="*" element={<PageNotFound />} />
                </Routes>
            default:
                return <Routes>
                    <Route exact path="/" element={<NoRoleUserMessage />} />
                </Routes>
        }
    }
    return (
        <Suspense fallback={<div>Loading...</div>}>
            {getRoleBasedRoutes()}
        </Suspense>
    );
}

export default AppRoutes;