import React, { useState, useEffect, useContext } from "react";
import { useLazyQuery } from '@apollo/client';
import { GET_ALL_TEMPLATES_FOR_A_JOB } from "../../services/queries/queries";
import { useLocation } from 'react-router-dom';
import { cloneDeep } from "lodash";
import { Box, Button, Grid, Typography } from "@mui/material";
import styled from "styled-components";
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { AppContext } from "../../services/context/AppContext";
import { UserRole } from "../../Constants/MetaData";


const ImageContainerBox = styled(Box)({
    position: "relative",
    height: "240px",
    width: "200px",
    overflow: "hidden",
    margin: "10px 0",
    background: "#fff",
    boxShadow: "2px 0 5px rgba(0, 0, 0, 0.8)",
})

const ImageLabelContainer = styled(Grid)({
    position: "absolute",
    bottom: 0,
    width: "100%",
    background: "rgba(0, 0, 0, 0.5)",
    padding: "5px",
    boxShadow: "2px 0 5px rgba(0, 0, 0, 0.4)",
})
const ImageLabel = styled(Typography)({
    color: "#fff",
    textOverflow: "ellipsis",
    fontSize: "11px !important",
    textAlign: "center",
})
const PreviewButton = styled(Button)({
    color: "#fff",
    textOverflow: "ellipsis",
    fontSize: "11px !important",
    textAlign: "center",
    textTransform: "capitalize !important",
    height: "25px"
})

const CheckedIcon = styled(CheckCircleRoundedIcon)({
    position: "absolute",
    top: 0,
    right: 0,
    color: "#32a852",
    margin: "5px",
    cursor: "pointer",
})
const UncheckedIcon = styled(RadioButtonUncheckedIcon)({
    position: "absolute",
    top: 0,
    right: 0,
    color: "grey",
    margin: "5px",
    cursor: "pointer",
})


function IframeTemplateDoc({ open, setActiveTemplate, activeTemplate, advediting, previewTemplate, roleType }) {
    const [templateList, setTemplateList] = useState([]);
    const [getTemplateByJobId] = useLazyQuery(GET_ALL_TEMPLATES_FOR_A_JOB, { fetchPolicy: 'network-only' });
    const location = useLocation();
    const [parentJobId] = useState(location.pathname.split("/").filter(item => item)[1]);
    const { showSideMenu } = useContext(AppContext);

    useEffect(() => {
        if ((!showSideMenu || advediting) && location && (location.pathname.indexOf("/typist/") > -1) || UserRole.proofReader === roleType) {
            const paths = location.pathname.split("/").filter(item => item);
            if (paths.length > 1) {
                const jobId = paths[1];
                getTemplateByJobId({ variables: { jobId: parseInt(jobId) } })
                    .then(({ loading, error, data }) => {
                        if (!loading && !error && data) {
                            const { documentsByJobId } = data;
                            if (documentsByJobId && documentsByJobId.client && documentsByJobId.client.templates &&
                                Array.isArray(documentsByJobId.client.templates) && documentsByJobId.client.templates.length) {
                                setTemplateList(cloneDeep(documentsByJobId.client.templates).sort((x, y) => y.isSystemTemplate - x.isSystemTemplate).map(temp => ({ ...temp, visible: true })));

                            }
                        }
                    });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [parentJobId])

    useEffect(() => {
        if (templateList && templateList.length && activeTemplate && activeTemplate?.id) {
            let _templateList = cloneDeep(templateList);
            if (activeTemplate?.changePosition) {
                _templateList = [..._templateList.filter(template => template.id === activeTemplate?.id), ..._templateList.filter(template => template.id !== activeTemplate?.id)].map(temp => ({ ...temp, visible: true }));
            }
            if (activeTemplate?.showOnlyActive) {
                _templateList = [..._templateList.filter(template => template.id === activeTemplate?.id).map(temp => ({ ...temp, visible: true })), ..._templateList.filter(template => template.id !== activeTemplate?.id).map(temp => ({ ...temp, visible: false }))];
            }
            setTemplateList(_templateList);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeTemplate])

    return (
        <div style={{ display: advediting === true ? 'flex' : 'inline', justifyContent: 'space-evenly', flexWrap: 'wrap', alignItems: 'flex-start', }}>
            {templateList && templateList?.filter(temp => temp.visible)?.map((item, index) => {
                return (open || advediting) && item?.thumbNailTemplateUrl !== null ? (
                    <ImageContainerBox key={index} style={{ width: advediting === true ? '25%' : '100%', margin: advediting === true ? '15px' : '' }}>
                        <Box onClick={() => {
                            if (UserRole.proofReader === roleType) {
                                setActiveTemplate({
                                    ...activeTemplate,
                                    id: item.id,
                                    changePosition: false,
                                    isProofreader: true
                                });
                            }
                            else {
                                setActiveTemplate({
                                    ...activeTemplate,
                                    id: item.id,
                                    changePosition: false,
                                    isProofreader: false
                                });
                            }
                        }}>
                            {item.id === activeTemplate?.id && <CheckedIcon />}
                            {item.id !== activeTemplate?.id && <UncheckedIcon />}
                            <img height={"240px"} width={"200px"}
                                src={`${item?.thumbNailTemplateUrl}`}
                                alt="Template" onError={({ currentTarget }) => {
                                    currentTarget.onerror = false;
                                    currentTarget.src = "/Assets/Images/img-error.png"
                                    currentTarget.alt = "Failed to load"
                                }} />
                        </Box>
                        <ImageLabelContainer display={"flex"} alignItems={"center"} gap={1}>
                            <ImageLabel noWrap title={item?.name}>
                                {item?.name}
                            </ImageLabel>
                            {UserRole.typist === roleType && <PreviewButton variant={"contained"} type="button" onClick={() => previewTemplate(item.id)}>Preview</PreviewButton>}
                        </ImageLabelContainer>
                    </ImageContainerBox >
                ) : null
            })}
        </div>
    );
}

export default IframeTemplateDoc;
