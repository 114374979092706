import React, { useState } from "react";
import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  Grid,
  Collapse,
  Menu,
  MenuItem,
} from "@mui/material";
import { Typography } from "@mui/material";
import { Icon } from "../icon/Icon";
import "../../styles/index.css";
import { useNavigate, useLocation } from "react-router-dom";
import { StyledFlex } from "../../styledComponents/Flex.styled";

function SideMenuListItem({ item, key, open, organizations, user }) {
  const [colleps, setColleps] = useState(true);
  const menuRef = React.createRef();
  let navigate = useNavigate();
  const location = useLocation();

  const [anchorEl, setAnchorEl] = useState(null);
  const openChildMenu = Boolean(anchorEl);
  const handleParentMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (item) => {
    if (item.children) {
      setColleps(!colleps);
    }
    else {
      navigate(item.route);
    }
  };

  return (
    <>
      <ListItem key={key} ref={menuRef} disablePadding sx={{ display: "block" }}>
        <ListItemButton
          sx={{
            justifyContent: open ? "center" : "",
            "&:hover": {
              backgroundColor: "#0F75BC",
              borderRadius: "30px",
            },
            backgroundColor:
              location.pathname === item.route || (item.children && item.children.length && item.children?.findIndex((child) => child.route === location.pathname) >= 0) ? "#0F75BC" : "#053446",
            borderRadius: "30px",
            marginBottom: "10px",
            padding: open ? "auto" : "8px 3px",
          }}
          onClick={(e) => handleClick(item)}
        >
          <Grid container flexDirection="row" alignItems="center">
            <Grid item xs={4}>
              <ListItemIcon
                sx={{
                  minWidth: "30px",
                  display: "flex",
                  justifyContent: "center",
                  height: "20px",
                }}
                onClick={handleParentMenuClick}
              >
                <Icon name={item.icon} size={"20"} color={"white"} />
              </ListItemIcon>
              {
                !open && item.children && (
                  <Menu
                    anchorEl={anchorEl}
                    open={openChildMenu}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                  >
                    {item.children.map((childItem, childIndex) => <MenuItem key={childIndex} selected={childItem.route === location.pathname} onClick={() => { handleClick(childItem); handleClose(); }}>
                      <StyledFlex>
                        <Typography sx={{ color: "black" }}>
                          {childItem.text}
                        </Typography>
                      </StyledFlex>
                    </MenuItem>)}
                  </Menu>
                )
              }
            </Grid>
            {open && (
              <Grid item xs={8}>
                <Grid container flexDirection="row" alignItems="center">
                  <Grid item xs={8}>
                    <Typography sx={{ color: "white" }}>
                      {item.text}
                    </Typography>
                  </Grid>
                  {item?.children && (
                    <Grid item xs={4}>
                      <Typography sx={{ color: "white", paddingTop: "2px" }}>
                        {/* here need to put dropdown icon */}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            )}
          </Grid>
        </ListItemButton>
      </ListItem>

      {open && (
        <Collapse key={key} in={colleps} timeout="auto" unmountOnExit>
          {item?.children &&
            item?.children.map((val) => (
              <ListItemButton
                sx={{
                  justifyContent: open ? "center" : "",
                  "&:hover": {
                    backgroundColor: "#0F75BC",
                    borderRadius: "30px",
                  },
                  backgroundColor:
                    location.pathname === val.route ? "#0F75BC" : "#053446",
                  borderRadius: "30px",
                  marginLeft: "40px",

                  marginBottom: "10px",
                }}
                onClick={(e) => handleClick(val)}
              >
                <Grid container flexDirection="row" alignItems="center">
                  <Grid item xs={4}>
                    <ListItemIcon
                      sx={{
                        minWidth: "30px",
                        display: "flex",
                        justifyContent: "center",
                        height: "20px",
                      }}
                    >
                      <>
                        <Icon name={val.icon} size={"20"} color={"white"} />
                      </>
                    </ListItemIcon>
                  </Grid>
                  <Grid item xs={8}>
                    {open && (
                      <Typography sx={{ color: "white" }}>
                        {val.text}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </ListItemButton>
            ))}
        </Collapse>
      )}
    </>
  );
}

export default SideMenuListItem;