import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  fromPromise,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { useAuth0 } from "@auth0/auth0-react";
import { Box, Typography } from "@mui/material";
import { ThemeProvider as MaterialThemeProvider } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import { ThemeProvider } from "styled-components";
import "./App.css";
import LoadingImage from "./Assets/Images/loader.gif";
import Layout from "./components/Layout";
import ErrorBoundary from "./ErrorBoundary";
import NoInternet from "./Pages/CommonPages/NoInternet";
import { AppContext } from "./services/context/AppContext";
import GraphqlInterceptor from "./services/GraphqlInterceptor";
import { AppTheme } from "./themes/theme";
import LoaderUtils from "./utils/LoaderUtils";
import ToastUtils from "./utils/ToasterUtils";

const App = () => {
  //const [isTokenSet, setIsTokenSet] = useState(false);
  const {
    isAuthenticated,
    isLoading,
    getAccessTokenSilently,
    loginWithRedirect,
    logout,
  } = useAuth0();
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [showSideMenu, setShowSideMenu] = useState(true);
  const [isGeneratingAIText, setIsGeneratingAIText] = useState(false);

  const logoutLink = onError(
    ({ operation, networkError, graphQLErrors, forward }) => {
      const { error = "Something went wrong" } = operation.getContext();
      LoaderUtils.decreaseAPICount();

      switch (networkError.statusCode) {
        case 401:
          return fromPromise(
            getAccessTokenSilently().catch(() => {
              ToastUtils.showToast("Please login again", ToastUtils.TYPE.ERROR);
              logout({ logoutParams: { returnTo: window.location.origin } });
              return;
            })
          )
            .filter((value) => Boolean(value))
            .flatMap((accessToken) => {
              localStorage.setItem("access_token", accessToken);
              return forward(operation);
            });
        case 500:
          if (
            Array.isArray(graphQLErrors) &&
            graphQLErrors.length &&
            graphQLErrors[0].message
          ) {
            ToastUtils.showToast(
              graphQLErrors[0].message,
              ToastUtils.TYPE.ERROR
            );
          } else {
            ToastUtils.showToast(
              "Oops, Server isn't responding",
              ToastUtils.TYPE.ERROR
            );
            // navigate("/server-down")
          }
          break;

        default:
          ToastUtils.showToast(error, ToastUtils.TYPE.ERROR);
          break;
      }
    }
  );

  const apolloClient = new ApolloClient({
    link: ApolloLink.from([logoutLink, ...GraphqlInterceptor.links]),
    cache: GraphqlInterceptor.cache,
    defaultOptions: GraphqlInterceptor.defaultOptions,
  });

  useEffect(() => {
    LoaderUtils.resetAPICount();
  }, []);

  useEffect(() => {
    const handleConnectivityStatus = () => {
      setIsOnline(navigator.onLine);
      ToastUtils.clearPreviousToasts();
    };
    window.addEventListener("online", handleConnectivityStatus);
    window.addEventListener("offline", handleConnectivityStatus);
    return () => {
      window.removeEventListener("online", handleConnectivityStatus);
      window.removeEventListener("offline", handleConnectivityStatus);
    };
  }, [isOnline]);

  useEffect(() => {
    const getToken = async () => {
      try {
        const token = await getAccessTokenSilently();
        localStorage.setItem("access_token", token);
        //setIsTokenSet(true);
      } catch (e) {
        //setIsTokenSet(false);
        //console.error(e);
      }
    };

    if (
      !isLoading &&
      isAuthenticated &&
      !localStorage.getItem("access_token")
    ) {
      getToken();
    } else if (!isLoading && !isAuthenticated) {
      loginWithRedirect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAccessTokenSilently, isAuthenticated, isLoading]);

  return (
    <>
      {!isOnline && <NoInternet />}
      {isOnline && <ToastContainer newestOnTop pauseOnHover={false} />}
      <MaterialThemeProvider theme={AppTheme}>
        <AppContext.Provider
          value={{
            showSideMenu,
            setShowSideMenu,
            isGeneratingAIText,
            setIsGeneratingAIText,
          }}
        >
          <ThemeProvider theme={AppTheme}>
            <ApolloProvider client={apolloClient}>
              <Box
                id="loading-container"
                className={`loading-wrapper ${
                  isGeneratingAIText ? "show" : ""
                }`}
              >
                <img
                  className="loading-indicator"
                  height={"80px"}
                  width={"80px"}
                  src={LoadingImage}
                  alt="loading-indicator"
                />
                {isGeneratingAIText && (
                  <Typography
                    variant="h6"
                     className="loading-text"
                  >
                    {"Please hold on while your transcription is being prepared...."}
                  </Typography>
                )}
              </Box>
              <ErrorBoundary>{<Layout />}</ErrorBoundary>
            </ApolloProvider>
          </ThemeProvider>
        </AppContext.Provider>
      </MaterialThemeProvider>
    </>
  );
};

export default App;
