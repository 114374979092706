import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import LogoutIcon from '@mui/icons-material/Logout';
import { Typography } from "@mui/material";
const LogoutButton = ({ open }) => {
    const { logout } = useAuth0();

    const handleLogout = () => {
        logout({ logoutParams: { returnTo: window.location.origin } })
        localStorage.clear();
    }
    const styles = {
        logout: {
            marginLeft: '6px',
            marginTop: '-40px'
        },
        logoutDiv: {
            display: 'flex',
            marginLeft: '17px',
            marginTop: "20px",
            cursor: 'pointer'
        },
        logoutIcon: {
            width: '30px',
            height: '30px',
            marginLeft: '5px'
        },
        text: {
            marginLeft: '25px'
        }
    }
    return (<>
        {!open ?
            <div onClick={handleLogout} style={styles.logout}>
                <LogoutIcon style={styles.logoutIcon} />
            </div>
            : <div style={styles.logoutDiv} onClick={handleLogout}>
                <LogoutIcon style={styles.logoutIcon} />
                <Typography style={styles.text} >{"Log Out"}</Typography>
            </div>
            // <Button onClick={handleLogout} variant="contained" color="primary">Log Out</Button>
        }

    </>)
};

export default LogoutButton;