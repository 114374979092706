import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import LogoutButton from '../logout';
import { ProfileWrapper, ProfileInfo } from "./profilestyle";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { UserRole } from "../../Constants/MetaData";

const Profile = ({ open, roleType }) => {
  const navigate = useNavigate();
  const { user, isAuthenticated, isLoading } = useAuth0();

  if (isLoading) {
    return <div>Loading ...</div>;
  }
  const styles = {
    profile: {
      marginBottom: '20px',
      cursor: 'pointer',
    },
    profileText: {
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "17px",
    },
    text: {
      marginLeft: "17px",
      whiteSpace: "nowrap",
      width: "107px",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    img: {
      marginLeft: '17px',
      borderRadius: "15px",
      height: '40px',
      width: '40px'
    },
    image: {
      borderRadius: "15px",
      marginLeft: '11px',
      marginBottom: '16px'
    },
  }
  const updateMyProfile = () => {
    if (roleType !== UserRole.admin && roleType !== UserRole.supervisor) {
      navigate("/my-profile");
    }
  }

  const handleProfileImageError = ({ currentTarget }) => {
    currentTarget.onerror = false;
    currentTarget.src = "";
    currentTarget.alt = getAvatarFromName(user.name);
  }

  const getAvatarFromName = (name) => {
    return name.split(" ").map(str => str[0]).join("");
  }

  return (
    isAuthenticated && (
      <ProfileWrapper>
        <ProfileInfo style={styles.profile} onClick={updateMyProfile}>
          {!open ? <div style={styles.profile}>
            <img style={styles.image} src={user?.picture} alt={"profilepicture"} onError={handleProfileImageError} /></div> :
            <img style={styles.img} src={user?.picture} alt={"profilepicture"} onError={handleProfileImageError} />
          }
          {open && <Typography style={styles.text}>{user?.name}</Typography>}
        </ProfileInfo>
        <LogoutButton open={open} />
      </ProfileWrapper>
    )
  );
};

export default Profile;