import { Button } from "@mui/material";
import styled from "styled-components";

export const StyledFlex = styled.div`
  display: flex;
  flex-direction: ${(props) => props.direction || "row"};
  align-items: ${(props) => props.align || "center"};
  justify-content: ${(props) => props.justify || "center"};
  height: ${(props) => props.height || "auto"};
  width: ${(props) => props.width || "auto"};
  flex-wrap: ${(props) => props.wrap || "no-wrap"};
  gap: ${(props) => props.gap || "0px"};
  flex: ${(props) => props.flex || "0 1 auto"};
  max-width: ${(props) => props.maxWidth || "auto"};
  margin-top: ${(props) => props.mt || "0px"};
  padding: ${(props) => props.padding || "0px"};
  margin: ${(props) => props.margin || "0px"};
`;

export const SpeedButton = styled(Button)`
margin-right:20px;
height:30px;
margin-top:3px;
`
