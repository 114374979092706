import { useAuth0 } from "@auth0/auth0-react";
import { Box, Divider, Grid, Drawer as MuiDrawer, Tooltip, Typography, styled } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import IframeTemplateDoc from "../../Pages/TypistPage/IframeTemplate";
import LoginButton from "../../auth/login";
import Profile from "../../auth/profile/profile";
import { AppContext } from "../../services/context/AppContext";
import { StyledFlex } from "../../styledComponents/Flex.styled";
import { StyledHamburgerIcon } from "../../styledComponents/SideMenu.styled";
import '../../styles/index.css';
import { Icon } from "../icon/Icon";
import { UserRole } from "./../..//Constants/MetaData";
import { CustomerMenuItems, ProofReaderMenuItems, SupervisorAndPRMenuItems, TypistMenuItems, adminMenuItems } from "./../../Constants/SideMenuItems";
import SideMenuListItem from "./SideMenuListItems";
// import TollFreeNumberChip from "./TollFreeNumberChip";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  width: `calc(${theme.spacing(8)} + 1px)`,
});


const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  overflowY: "visible",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function SideMenu({ open, toggle, onToggle, roleType, onTemplateChange, activeTemplate, previewTemplate }) {
  const { isAuthenticated } = useAuth0();
  const [menuItemLists, setMenuItemLists] = useState([]);
  const { showSideMenu } = useContext(AppContext);

  const styles = {
    divider: {
      backgroundColor: "white",
      width: 180,
      margin: "10px 0"
    },
    menuWrapper: { padding: "0 15px", overflow: "auto", height: "calc(100vh - 220px)", width: "calc(100% - 30px)" },
    templateContainer: { color: "white", padding: "15px 22px" },
    textField: {
      textAlign: "center",
      width: "200px",
      marginBottom: "15px",
      borderRadius: "20px"
    },
  }

  useEffect(() => {
    switch (roleType) {
      case UserRole.typist:
        setMenuItemLists(TypistMenuItems);
        break;
      case UserRole.admin:
        setMenuItemLists(adminMenuItems);
        break;
      case UserRole.proofReader:
        setMenuItemLists(ProofReaderMenuItems);
        break;
      case UserRole.supervisor:
        setMenuItemLists(SupervisorAndPRMenuItems);
        break;
      case UserRole.customer:
        setMenuItemLists(CustomerMenuItems);
        break;
      default:
        break;
    }
  }, [roleType]);

  return (
    <Drawer
      open={open}
      variant="permanent"
      anchor="left"
      PaperProps={{
        style: {
          overflowY: "visible",
        },
      }}
    >
      <Box margin={3}>
        <StyledFlex>
          <Box visibility={open ? "visible" : "hidden"}>
            <Typography color={"white"}>E-Typist</Typography>
            <Divider style={styles.divider} />
          </Box>
          {
            toggle &&
            <Tooltip
              title={open ? "Show less information" : "Show more information"}
            >
              <StyledHamburgerIcon onClick={onToggle} role="button" aria-label="Toggle menu">
                <Icon name={"burger"} color={"white"} size={"20px"} hidden="true" />
              </StyledHamburgerIcon>
            </Tooltip>
          }

        </StyledFlex>
      </Box>
      <>
        <Box className="sidebar-list">
          <div style={styles.menuWrapper}>
            {
              showSideMenu ? (
                menuItemLists.map((item, index) => (
                  <SideMenuListItem item={item} open={open} />
                ))
              ) : (
                <Grid display={"flex"} justifyContent={"center"}>
                  <IframeTemplateDoc
                    open={open}
                    setActiveTemplate={onTemplateChange}
                    activeTemplate={activeTemplate}
                    previewTemplate={previewTemplate} 
                    roleType={roleType}/>
                </Grid>
              )
            }
          </div>
        </Box>
        <div>
          {/* <StyledFlex>
            {UserRole.customer === roleType ? (
              <TollFreeNumberChip sidemenu={open} />
            ) : (
              <></>
            )}
          </StyledFlex> */}
          <StyledFlex>
            {isAuthenticated ? (
              <Profile open={open} roleType={roleType} />
            ) : (
              <LoginButton open={open} />
            )}
          </StyledFlex>
        </div>
      </>
    </Drawer>
  );
}