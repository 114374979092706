import styled from "styled-components";

export const ProfileWrapper = styled.div`
    display: block;
    text-align: center;
    color: ${props => props.theme.colors.white};
`
export const ProfileInfo = styled.div`
    display: flex;
    align-items: center;
    img {
        width: auto;
        height: 30px;
    }
`