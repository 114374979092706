export const adminMenuItems = [
  {
    text: "Jobs",
    route: "/",
    icon: "dashboard",
  },
  {
    text: "Users",
    icon: "headphones",
    route: "/users",
  },
  {
    text: "Clients",
    icon: "micro",
    route: "/clients",
  },
  {
    text: "Invite",
    icon: "add-user",
    route: "/invite",
  },
  {
    text: "Reports",
    icon: "graph",
    route: "/report",
    children: [
      {
        text: "Typist",
        route: "/reports/typist",
      },
      {
        text: "Client",
        route: "/reports/client",
      },
    ],
  },
];

export const CustomerMenuItems = [
  {
    text: "Job History",
    route: "/",
    icon: "dashboard",
  },
  {
    text: "Refer Colleague",
    route: "/invite",
    icon: "add-user"
  },
  {
    text: "Templates",
    route: "/templates",
    icon: "graph"
  },
];

export const SupervisorAndPRMenuItems = [
  {
    text: "Jobs",
    route: "/",
    icon: "dashboard",
  },
  {
    text: "Users",
    route: "/users",
    icon: "headphones",
  },
  {
    text: "Clients",
    icon: "micro",
    route: "/clients",
  },
];

export const TypistMenuItems = [
  {
    text: "Jobs",
    route: "/",
    icon: "dashboard"

  },
];
export const ProofReaderMenuItems = [
  {
    text: "Jobs",
    route: "/",
    icon: "dashboard"

  },
  {
    text: "Users",
    route: "/users",
    icon: "headphones"

  },
  {
    text: "Clients",
    route: "/clients",
    icon: "graph"

  },
]
