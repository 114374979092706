import { createTheme } from "@mui/material/styles";

export const theme = {
    primary: "#DF8807",
    secondary: "#004DDA",
    outlined: "#f8f9fa",
    tertiary: "#A1A1A1",
    icon: "#F4F6F9",

    colors: {
        white: "white",
        primaryOver: '#de6814',
        secondaryOver: '#0d0da2',
        outlineOver: '#ebedf2',
        tertiaryOver: '#6c6d70',
        red: 'red',
        blue: "#004DDA",
        dark: "#707070",
        lightGrey: "#D7D7D7",
        black: "#3A3A3A",
        powderWhite: "#FFFDF9",
        persianGreen: "#06B49A",
        lightBlue: "#AFDBD2",
        onyx: "#36313D",
        mediumLightGray: ' #AFAFAF',
        darkGray: '#454545',
        focusColor: '#1d2438'
    },
    fontSize: {
        small: "18px",
        medium: "20px",
        large: "24px"
    },
    fontWeight: {
        normal: 'normal',
        bold: 'bold'
    },
    fontStyled: {
        normal: 'normal',
        italic: 'italic'
    },
    typography: {
        allVariants: {
            fontFamily: 'OpenSans',
        },
    },
};

export const AppTheme = createTheme(theme);
