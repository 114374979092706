import React, { useState, useEffect, useContext } from "react";
import '../../styles/index.css';
import AppRoutes from "../../routing/routes";
import Box from "@mui/material/Box";
import { useAuth0 } from "@auth0/auth0-react";
import SideMenu from "./SideMenu";
import styled from "styled-components";
import { RoleUrl } from "../../Constants/MetaData";
import { AppContext } from "../../services/context/AppContext";

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    flexGrow: 1,
    padding: "20px 20px 20px 30px",
    height: "calc(100vh - 40px)",
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `calc(${theme.spacing(8)} + 1px)`,
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: drawerWidth,
    }),
}));


export default function Layout() {
    const { user, isAuthenticated, isLoading } = useAuth0();
    const [open, setOpen] = useState(true);
    const [roleType, setRoleType] = useState("");
    const { showSideMenu } = useContext(AppContext);
    const [isAuthReady, setIsAuthReady] = useState(false);

    useEffect(() => {
        if (user) {
            setRoleType(user[RoleUrl]);
        };

    }, [user]);

    useEffect(() => {
        if (!isLoading) {
            setIsAuthReady(true);
        }
    }, [isLoading]);

    const handleDrawer = () => {
        setOpen(!open);
    };

    return (
        <Box>
            {isAuthReady && isAuthenticated && (
                <SideMenu toggle={showSideMenu} open={open} onToggle={handleDrawer} roleType={roleType} />
            )}
            <Main open={open}>
                <AppRoutes isAuthenticated={isAuthenticated} roleType={roleType} />
            </Main>
        </Box>
    );
}