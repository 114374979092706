import React from 'react'
import PropTypes from 'prop-types';
import sprite from "./icon.sprite.svg"

/**
 * Icon component for rendering various icons used in App
 */
export const Icon = ({ name, size, color, style, handleClient }) => {
  return (
    <svg width={size} fill={color} height={size} style={style} onClick={handleClient}>
      <use xlinkHref={`${sprite}#${name}`} />
    </svg>
  )
}

Icon.propTypes = {
  /**
   * Icon name/id from SVG Sprite (Required field)
   */
  name: PropTypes.string.isRequired,
  /**
   * Icon Size to be displayed
   */
  size: PropTypes.number,
  /**
   * Color of the icon
   */
  color: PropTypes.string
}

Icon.defaultProps = {
  name: null,
  size: null,
  color: null
}