import { ApolloLink, InMemoryCache, Observable } from "@apollo/client";
import { createUploadLink } from 'apollo-upload-client';
import LoaderUtils from '../utils/LoaderUtils';
import ToastUtils from "../utils/ToasterUtils";


const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
}

// const httpLink = createHttpLink({
//   uri: process.env.REACT_APP_APOLLO_CLIENT_URL,
// });

/**
 * Reference: https://www.apollographql.com/docs/react/data/file-uploads/
 */
const uploadLink = createUploadLink({
  uri: process.env.REACT_APP_APOLLO_CLIENT_URL,
});

const reqResLink = new ApolloLink((operation, forward) => {
  // add the authorization to the headers

  operation.setContext(({ headers = {} }) => {
    const { headers: newHeaders, showLoader = true } = operation.getContext();
    if (newHeaders) {
      headers = { ...headers, ...newHeaders }
    }
    showLoader && LoaderUtils.increaseAPICount();
    return {
      headers: {
        ...headers,
        authorization: `Bearer ${localStorage.getItem('access_token')}` || null,
      }
    };
  });

  return forward(operation).map(response => {
    const { success, showLoader = true } = operation.getContext();
    if (success) {
      ToastUtils.showToast(success, ToastUtils.TYPE.SUCCESS);
    }
    showLoader && LoaderUtils.decreaseAPICount();
    return response;
  });
})

const abortLink = new ApolloLink((operation, forward) => {
  const controller = new AbortController();
  const observable = new Observable(observer => {
    const subscription = forward(operation).subscribe({
      next: observer.next.bind(observer),
      error: observer.error.bind(observer),
      complete: observer.complete.bind(observer),
    });
    return () => {
      controller.abort();
      subscription.unsubscribe();
      if (!operation.getContext().response) {
        LoaderUtils.decreaseAPICount();
      }
    };
  });

  return observable;
});

const links = [
  abortLink,
  reqResLink,
  uploadLink,
];


const cache = new InMemoryCache({
  addTypename: false,
});

const apolloConfig = { links, cache, defaultOptions };
export default apolloConfig;