import { gql } from "@apollo/client";

// const GET_ALL_JOBS = gql`
// query GetJobs($filterByStatus: Status){
//   jobs (where:{status: {eq: $filterByStatus}}){
//        id
//    name
//    audioAttachement
//    length
//    transcriptionText
//    status
//    typist {
//    id
//    email
//   }
//   }
//   }
// `;

// const GET_SUPERVISOR_USERS = gql`
//  query ($userType1:String, $userType2:String){
//     typists:users(where:{role:{eq:$userType1}}){
//       id
//       role
//       firstName
//       lastName
//       email
//       mobileNumber
//       createdDate
//       wordRate
//       }
//    proofReaders:users(where:{role:{eq:$userType2}}){
//         id
//         firstName
//         lastName
//         email
//         mobileNumber
//         role
//         createdDate
//         wordRate
//         }
//     }
// `;
const GET_SUPERVISOR_USERS = gql`
  query ($userType1: String, $userType2: String, $skip: Int, $take: Int) {
    users(
      where: {
        or: [{ role: { eq: $userType1 } }, { role: { eq: $userType2 } }]
      }
      order: { createdDate: DESC },
       skip: $skip,
        take: $take
    ) {
      totalCount
      pageInfo {
      hasNextPage
      hasPreviousPage
    }
      items {
      id
      firstName
      userNumber
      lastName
      userNumber
      mobileNumber
      email
      role
      wordRate
      createdDate
    }
  }
}
`;

const GET_MY_CLIENT_PROFILE = gql`
  query {
    client {
      id
      firstName
      lastName
      email
      mobileNumber
      ccEmails
    }
  }
`;
const GET_MY_TYPIST_PROFILE = gql`
  query {
    typist {
      id
      firstName
      lastName
      email
      mobileNumber
      role
      wordRate
      lengthPool
    }
  }
`;
const GET_MY_PROOFREADER_PROFILE = gql`
  query {
    proofReader {
      id
      firstName
      lastName
      email
      mobileNumber
    }
  }
`;

const GET_ALL_JOBS = gql`
  query($skip: Int!, $take: Int!) {
    jobs(
      skip: $skip
      take: $take

      order: { createdDate: DESC }
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      items {
        id
        name
        audioFileName
        audioAttachement
        length
        transcriptionText
        appliedTemplateTranscriptionText
        status
        wordCount
        completedDate
        clientId
        rushJob
        dueDate
        jobNumber
        feedback
        proofreaderFeedback
        createdDate
        transcriptionDocumentURL
        wordCount
        consumedTime
        transcriptionStatus
        reason
        assigned
        client {
          firstName
          userNumber
          lastName
          id
          wordRate
        }
        typist {
          id
          firstName
          lastName
          email
          userNumber
          wordRate
        }
      }
    }
  }
`;


const GET_ALL_TEMPLATE = gql`
  query ($clientId: UUID!) {
    documentsByClientId(clientId: $clientId, order: { createdDate: DESC }) {
      id
      name
      thumbNailTemplateUrl
      isSystemTemplate
    }
  }
`;

const GET_ROLE_TYPE = gql`
  query {
    typists {
      role
    }
  }
`;

const GET_LOCATIONS = gql`
  query GetLocations {
    locations {
      id
      name
      description
      photo
    }
  }
`;

const GET_MANAGE_ADMIN_ALL_CLIENTS = gql`
  query {
    clients(order: { createdDate: DESC }) {
      dialInNumber
      dictationUserId
      dictationPasscode
      siteNumber
      userNumber
      category
      jobs {
        id
      }

      templates {
        name
      }
      id
      userNumber
      externalId
      firstName
      lastName
      email
      role
      isDeleted
      mobileNumber
      wordRate
      userType
      status
      createdDate
      createdBy
      modifiedDate
      modifiedBy
    }
  }
`;

const GET_TYPIST_LIST = gql`
  query {
    typists(order: { createdDate: DESC }) {
      firstName
      lastName
      id
      userNumber
      createdDate
      wordRate
      typistSkills {
        experienceLevel
      }
    }
  }
`;

const SET_ADD_NEW_CLIENT = gql`
  mutation ($addClient: ClientInviteRequestInput!) {
    addClient(clientInviteRequest: $addClient) {
      id
      firstName
      status
    }
  }
`;

const SET_TYPIST_NEW_USER = gql`
  mutation ($inviteTypist: InviteUserRequestInput!) {
    inviteTypist(inviteUserRequest: $inviteTypist) {
      id
      firstName
      lastName
      mobileNumber
      email
      wordRate
      role
    }
  }
`;
const SET_PROOFREADER_NEW_USER = gql`
  mutation ($inviteProofReader: InviteUserRequestInput!) {
    inviteProofReader(inviteUserRequest: $inviteProofReader) {
      id
      firstName
      lastName
      mobileNumber
      email
      wordRate
      role
    }
  }
`;
const GET_TYPIST_JOB_INFO = gql`
  query ($Id: UUID) {
    typists(where: { id: { eq: $Id } }) {
      id
      firstName
      lastName
      userNumber
      jobs {
        id
        name
        jobNumber
        length
        status
        clientId
        client {
          id
          firstName
          lastName
          userNumber
        }
      }
    }
  }
`;
const GET_CUSTOMERS_ALL_JOBS = gql`
  query {
    clients {
      id
      userNumber
      jobs {
        createdDate
        modifiedDate
        completedDate
        jobNumber
        length
      }
    }
  }
`;

const INVITE_NEW_CLIENT = gql`
  mutation ($inviteClient: ClientInviteRequestInput!) {
    inviteClient(clientInviteRequest: $inviteClient) {
      id
      firstName
      lastName
      mobileNumber
      email
      wordRate
      siteNumber
    }
  }
`;
const EDIT_CLIENT = gql`
  mutation updateUserByAdminOrSupervisor(
    $updateUserRequest: UpdateUserRequestInput!
  ) {
    updateUserByAdminOrSupervisor(updateUserRequest: $updateUserRequest) {
      id
      firstName
      lastName
    }
  }
`;
const DEACTIVATE_CLIENT = gql`
  mutation DisableInputRequest($inputRequest: InputRequestInput!) {
    disable(inputRequest: $inputRequest) {
      id
    }
  }
`;

const ACTIVATE_CLIENT = gql`
  mutation ($input: InputRequestInput!) {
    enable(inputRequest: $input) {
      id
    }
  }
`;

const RESEND_CLIENT = gql`
  mutation ($email: String!) {
    resendInvite(email: $email)
  }
`;

const UPDATE_TYPIST_USERS = gql`
  mutation updateUserByAdminOrSupervisor(
    $updateUserRequest: UpdateUserRequestInput!
  ) {
    updateUserByAdminOrSupervisor(updateUserRequest: $updateUserRequest) {
      id
      firstName
      lastName
    }
  }
`;
const UPDATE_PROOFREADER_USERS = gql`
  mutation updateUserByAdminOrSupervisor(
    $updateUserRequest: UpdateUserRequestInput!
  ) {
    updateUserByAdminOrSupervisor(updateUserRequest: $updateUserRequest) {
      id
      firstName
      lastName
    }
  }
`;

const CLIENT_INFO = gql`
  query {
    client {
      id
      externalId
      firstName
      lastName
      email
      role
      isDeleted
      mobileNumber
      wordRate
      userType
      status
      createdDate
      createdBy
      modifiedDate
      modifiedBy
      dialInNumber
      dictationUserId
      dictationPasscode
      templates {
        clientId
        id
        isSystemTemplate
        name
        thumbNailTemplateUrl
        formatType
        url
        modifiedDate
        createdDate
      }
      jobs {
        id
        name
        status
        createdDate
        completedDate
        audioAttachement
        audioFileName
        status
        transcriptionDocumentURL

        typist {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

// put this in jobs{} later: transcriptionStatus
const TYPIST_INFO = gql`
  query {
    typist {
      jobs {
        id
        name
        jobNumber
        createdDate
        createdBy
        transcriptionText
        feedback
        proofreaderFeedback
        appliedTemplateTranscriptionText
        transcriptionDocumentURL
        length
        rushJob
        audioAttachement
        status
        reason
        transcriptionStatus
        client {
          userNumber
          firstName
          lastName
          id
        }
      }
    }
  }
`;

const GET_DELIVERED_JOBS_LIST = gql`
  query($billingReportRequest: BillingReportRequestInput!, $skip: Int!, $take: Int!) {
    billingReports(billingReportRequest: $billingReportRequest, skip: $skip, take: $take) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      items {
        typistId
        clientId
        typistName
        clientName
        jobNumber
        jobName
        serviceDate
        billAmount
        billingReportUrl
        typistGuidId
        clientGuidId
        jobId
        proofredaerWordCount
        typistWordCount
        siteNumber
      }
    }
  }
`;


const GET_DELIVERED_JOB = gql`
  query GetBillingReportResponses(
    $billingReportRequest: BillingReportRequestInput!
  ) {
    billingReportResponse(billingReportRequest: $billingReportRequest) {
      billingReportUrl
    }
  }
`;

const GET_JOB_BY_ID = gql`
  query JobById($jobId: Int!) {
    jobById(jobId: $jobId) {
      id
      transcriptionText
      consumedTime
      templateId
      status
      feedback
      proofreaderFeedback
      jobLength
      audioAttachement
      transcriptionDocumentURL
      noOfSplits
      jobNumber
      wordCount
      name
      fileDownloaded
      proofReaderFileDownloaded
      isParentJob
      length
      appliedTemplateTranscriptionText
      transcriptionStatus
    }
  }
`;

const REQUEST_A_NEW_JOBS = gql`
  query {
    requestJob {
      id
      firstName
      lastName
      jobs {
        id
        name
        transcriptionText
        appliedTemplateTranscriptionText
        fileDownloaded
        proofReaderFileDownloaded
      }
    }
  }
`;
const DOWNLOAD_ADVANCED_EDITING = gql`
  mutation ($input: AdvancedEditRequestInput!) {
    advancedEditing(advancedEditRequest: $input) {
      id
      transcriptionText
      transcriptionDocumentURL
    }
  }
`;
const UPDATE_JOBS_BY_USERS = gql`
  mutation ($upload: Upload!, $jobId: UUID!) {
    uploadTemplateByUsers(file: $upload, jobId: $jobId) {
      id
      transcriptionDocumentURL
      transcriptionText
      appliedTemplateTranscriptionText
      wordCount
    }
  }
`;
const GET_ALL_TEMPLATES_FOR_A_JOB = gql`
  query ($jobId: Int!) {
    documentsByJobId(jobId: $jobId, order: { createdDate: DESC }) {
      client {
        templates {
          id
          name
          url
          thumbNailTemplateUrl
          isSystemTemplate
        }
      }
    }
  }
`;

const CREATE_SPLIT_FOR_A_JOB = gql`
  mutation ($parentJobId: UUID!) {
    createSplitForJob(parentJobId: $parentJobId) {
      id
      name
      noOfSplits
      parentJobId
      isParentJob
      jobNumber
      transcriptionText
      appliedTemplateTranscriptionText
      fileDownloaded
      proofReaderFileDownloaded
    }
  }
`;

const GET_SPLITTED_JOBS_FOR_A_JOB = gql`
  query ($parentJobId: Int!) {
    restOfTheJobFamily(parentJobId: $parentJobId) {
      noOfSplits
      jobFamilyMembers {
        id
        name
        transcriptionText
        appliedTemplateTranscriptionText
        status
        parentJobId
        isParentJob
        jobNumber
        templateId
        feedback
        proofreaderFeedback
        transcriptionDocumentURL
        wordCount
        fileDownloaded
        appliedTemplateTranscriptionText
        proofReaderFileDownloaded
      }
    }
  }
`;

const JOBS_ON_HOLD = gql`
  mutation HoldJob($jobhold: JobOnHoldRequestInput!) {
    holdJob(jobOnHoldRequest: $jobhold) {
      id
      name
      status
    }
  }
`;

const GET_CLIENT_BY_ID = gql`
  query clientById($clientId: Int!) {
    clientById(clientid: $clientId) {
      id
      firstName
      lastName
      siteNumber
      wordRate
      mobileNumber
      dialInNumber
      dictationPasscode
      dictationUserId
      email
      emailVerified
      status
      ccEmails
    }
  }
`;

const GET_USER_BY_ID = gql`
  query ($userNumber: Int!) {
    users(where: { userNumber: { eq: $userNumber } }) {
      id
      firstName
      lastName
      mobileNumber
      email
      wordRate
      role
      userNumber
    }
  }
`;

const GET_TYPIST_BY_ID = gql`
  query ($typistId: Int!) {
    typistById(typistId: $typistId) {
      id
      firstName
      lastName
      mobileNumber
      email
      wordRate
      ids
      lengthPool
      role
      emailVerified
      status
    }
  }
`;

const GET_PROOFREADER_BY_ID = gql`
  query ($proofreaderId: Int!) {
    proofReaderById(proofreaderId: $proofreaderId) {
      id
      firstName
      lastName
      mobileNumber
      email
      wordRate
      role
      emailVerified
      status
    }
  }
`;

const DOWNLOAD_FINAL_TRANSCRIPT = gql`
  query ($jobNumber: Int!) {
    finalDocumentUrlForJob(jobNumber: $jobNumber)
  }
`;

export {
  ACTIVATE_CLIENT,
  CLIENT_INFO,
  CREATE_SPLIT_FOR_A_JOB,
  DEACTIVATE_CLIENT,
  DOWNLOAD_ADVANCED_EDITING,
  DOWNLOAD_FINAL_TRANSCRIPT,
  EDIT_CLIENT,
  GET_ALL_JOBS,
  GET_ALL_TEMPLATE,
  GET_ALL_TEMPLATES_FOR_A_JOB,
  GET_CLIENT_BY_ID,
  GET_CUSTOMERS_ALL_JOBS,
  GET_DELIVERED_JOB,
  GET_DELIVERED_JOBS_LIST,
  GET_JOB_BY_ID,
  GET_LOCATIONS,
  GET_MANAGE_ADMIN_ALL_CLIENTS,
  GET_MY_CLIENT_PROFILE,
  GET_MY_PROOFREADER_PROFILE,
  GET_MY_TYPIST_PROFILE,
  GET_PROOFREADER_BY_ID,
  GET_ROLE_TYPE,
  GET_SPLITTED_JOBS_FOR_A_JOB,
  GET_SUPERVISOR_USERS,
  GET_TYPIST_BY_ID,
  GET_TYPIST_JOB_INFO,
  GET_TYPIST_LIST,
  GET_USER_BY_ID,
  INVITE_NEW_CLIENT,
  JOBS_ON_HOLD,
  REQUEST_A_NEW_JOBS,
  RESEND_CLIENT,
  SET_ADD_NEW_CLIENT,
  SET_PROOFREADER_NEW_USER,
  SET_TYPIST_NEW_USER,
  TYPIST_INFO,
  UPDATE_JOBS_BY_USERS,
  UPDATE_PROOFREADER_USERS,
  UPDATE_TYPIST_USERS
};

