import { Avatar } from "@mui/material";
import styled from "styled-components";

// export const StyledSideMenu = styled.div`
//   .side-drawer {
//     & > div {
//       // paper stylings for drawer
//       &.logo-wrapper {
//         position: relative;
//         width: 100%;
//       }
//       background-color: #053446;
//       display: flex;
//       align-items: flex-start;
//       padding-top: 5px;
//       overflow: initial;

//       div.side-menu-list {
//         display: flex;
//         flex-direction: column;
//         align-items: center;
//         justify-content: space-between;
//         height: 100%;
//       }
//     }
//   }
// `;

export const StyledSubSideMenu = styled.div`
  .side-drawer {
    width: 200px;
    height: 100%;
    div {
      // paper stylings for drawer
      width: 200px;
      margin-left: 60px;
      height: 100%;
    }
  }
`;

export const StyledHamburgerIcon = styled(Avatar)({
  position: "absolute !important",
  background: "white !important",
  right: "-20px",
  boxShadow: "0 2px 5px rgba(0, 0, 0, 0.7)",
  cursor: "pointer",
});
