import React from "react";
import { StyledFlex } from "../../styledComponents/Flex.styled";
import { Typography, IconButton, Box } from "@mui/material";
import SignalWifiStatusbarConnectedNoInternet4Icon from '@mui/icons-material/SignalWifiStatusbarConnectedNoInternet4';

const NoInternet = () => {
  return (
    <Box
      position={"fixed"}
      width={"100%"}
      zIndex={9998}
      style={{ background: "red", color: "white" }}
      enableColorOnDark
    >
      <StyledFlex gap="20px">
        <StyledFlex direction="row">
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
            <SignalWifiStatusbarConnectedNoInternet4Icon />
          </IconButton>
          <Typography
            style={{ textAlign: "center", fontWeight: 400 }}
            color="white"
          >
            {"No Internet"}
          </Typography>
        </StyledFlex>
      </StyledFlex>
    </Box>
  );
}

export default NoInternet;