import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { Button } from "@mui/material";
import { Icon } from "../components/icon/Icon";
const LoginButton = ({ open }) => {
  const { loginWithRedirect } = useAuth0();

  const handleLogin = () => {
    loginWithRedirect();
  };

  return (
    <>
      {!open ? (
        <div
          onClick={handleLogin}
          style={{ marginLeft: "3px", marginBottom: "20px" }}
        >
          <Icon name={"user"} color={"#fff"} size={"34px"} />
        </div>
      ) : (
        <Button
          onClick={handleLogin}
          variant="outlined"
          color="primary"
          style={{ color: "white" }}
        >
          Log In
        </Button>
      )}
    </>
  );
};

export default LoginButton;
